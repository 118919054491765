import { PropagateLoader } from 'react-spinners';

export default function LoadingBox() {
  return (
    <div className="d-flex justify-content-center my-5">
      <PropagateLoader color="#57c9b4" speedMultiplier={0.75}>
        <span className="visually-hidden">Loading...</span>
      </PropagateLoader>
    </div>
  );
}

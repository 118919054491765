import React, { useEffect, Suspense, lazy } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { useContext, useState } from 'react';
import { Store } from './Store';
import { isBrowser } from 'react-device-detect';
import Sidebar from './components/Sidebar';
import AdminRoute from './components/AdminRoute';

import HomeScreen from './views/HomeScreen';
/*
import ProductScreen from './views/ProductScreen';
import CartScreen from './views/CartScreen';
import SigninScreen from './views/Admin/SigninScreen';
import SignupScreen from './views/Admin/SignupScreen';
import OrderScreen from './views/Order/OrderScreen';
import OrderHistoryScreen from './views/Admin/OrderHistoryScreen';
import ShopScreen from './views/ShopScreen';
import FactorScreen from './views/Admin/FactorScreen';
import ReportScreen from './views/Admin/ReportScreen';
import WebApp from 'views/Info/WebApp';
import PlaceOrderUserDataScreen from 'views/Order/PlaceOrderUserDataScreen';
import PlaceOrderDeliveryScreen from 'views/Order/PlaceOrderDeliveryScreen';
import PlaceOrderPaymentMethodScreen from 'views/Order/PlaceOrderPaymentmethodScreen';
import PlaceOrderOrderSummaryScreen from 'views/Order/PlaceOrderOrderSummaryScreen';
import ImpressumScreen from 'views/LegalTexts/ImpressumScreen';
import PayAndDeliveryScreen from 'views/LegalTexts/PayAndDeliveryScreen';
import RevocationScreen from 'views/LegalTexts/RevocationScreen';
import DataProtectionScreen from 'views/LegalTexts/DataProtectionScreen';
import AGBScreen from 'views/LegalTexts/AGBScreen';
import BaggerScreen from 'views/BaggerScreen';
*/

import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import BecomeSellerScreen from 'views/Info/BecomeSellerScreen';
import useAuth from 'utils/utils';
import CartSidebar from 'components/CartSidebar';
import { ReactComponent as BagIcon } from 'assets/images/local_mall.svg';
import { shopType } from 'utils/types';
import Marquee from 'react-fast-marquee';
import ReactGA from 'react-ga4';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons/faRectangleXmark';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons/faCartPlus';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping';
import LoadingBox from 'components/LoadingBox';
import Footer from 'components/Footer';
import { Modal } from 'react-bootstrap';
import UrlaubImg from './assets/images/vacation-background.webp';
import TrackingDashboardScreen from 'views/Admin/TrackingDashboard';

library.add(
  faRectangleXmark,
  faCircle,
  faXmark,
  faChevronLeft,
  faTrash,
  faArrowLeft,
  faBars,
  faAngleUp,
  faAngleDown,
  faCircleInfo,
  faCartPlus,
  faSliders,
  faSpinner,
  faFilePdf,
  faCartShopping
);

//const HomeScreen = lazy(() => import('./views/HomeScreen'));
const ProductScreen = lazy(() => import('./views/ProductScreen'));
const CartScreen = lazy(() => import('./views/CartScreen'));
const SigninScreen = lazy(() => import('./views/Admin/SigninScreen'));
const SignupScreen = lazy(() => import('./views/Admin/SignupScreen'));
const OrderScreen = lazy(() => import('./views/Order/OrderScreen'));
const OrderHistoryScreen = lazy(() =>
  import('./views/Admin/OrderHistoryScreen')
);
const ShopScreen = lazy(() => import('./views/ShopScreen'));
const FactorScreen = lazy(() => import('./views/Admin/FactorScreen'));
const ReportScreen = lazy(() => import('./views/Admin/ReportScreen'));
const DashboardScreen = lazy(() => import('./views/Admin/DashboardScreen'));
const AboutUsScreen = lazy(() => import('./views/Info/AboutUsScreen'));
const NewProductScreen = lazy(() => import('./views/Admin/NewProductScreen'));
const ProductListScreen = lazy(() => import('./views/Admin/ProductListScreen'));
const ProductEditScreen = lazy(() => import('./views/Admin/ProductEditScreen'));
const WebApp = lazy(() => import('views/Info/WebApp'));
const PlaceOrderUserDataScreen = lazy(() =>
  import('views/Order/PlaceOrderUserDataScreen')
);
const PlaceOrderDeliveryScreen = lazy(() =>
  import('views/Order/PlaceOrderDeliveryScreen')
);
const PlaceOrderPaymentMethodScreen = lazy(() =>
  import('views/Order/PlaceOrderPaymentmethodScreen')
);
const PlaceOrderOrderSummaryScreen = lazy(() =>
  import('views/Order/PlaceOrderOrderSummaryScreen')
);
const ImpressumScreen = lazy(() => import('views/LegalTexts/ImpressumScreen'));
const PayAndDeliveryScreen = lazy(() =>
  import('views/LegalTexts/PayAndDeliveryScreen')
);
const RevocationScreen = lazy(() =>
  import('views/LegalTexts/RevocationScreen')
);
const DataProtectionScreen = lazy(() =>
  import('views/LegalTexts/DataProtectionScreen')
);
const AGBScreen = lazy(() => import('views/LegalTexts/AGBScreen'));
const BaggerScreen = lazy(() => import('views/BaggerScreen'));
const SaleScreen = lazy(() => import('views/Admin/SaleScreen'));

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div>
      <Row className="mt-5 mb-3">
        <Col className="w-100 d-flex justify-content-center">
          <strong>Oh da ist leider etwas schief gelaufen :(</strong>
        </Col>
      </Row>
      <Row>
        <Col className="w-100 d-flex justify-content-center">
          <div className="btn_action" onClick={resetErrorBoundary}>
            Versuche es erneut
          </div>
        </Col>
      </Row>
    </div>
  );
}

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, id } = state;

  useEffect(() => {
    if (id === '') {
      ctxDispatch({ type: 'SET_UUID' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authState = useAuth();

  // Deny ga4 until cookies accepted
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });

  // useEffect(() => {
  //   const initReactGA = async () => {
  //     ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
  //   };
  //   initReactGA();
  // }, []);

  //const { pathname } = useLocation();

  const signoutHandler = () => {
    try {
      ctxDispatch({ type: 'USER_SIGNOUT' });
      localStorage.removeItem('userInfo');
      localStorage.removeItem('shippingAddress');
      localStorage.removeItem('paymentMethod');
      window.location.href = '/admin/signin';
    } catch (error) {
      console.log(error);
    }
  };

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [cartSidebarIsOpen, setCartSidebarIsOpen] = useState(false);

  // Urlaubs Modal
  const [showUrlaubsModal, setShowUrlaubModal] = useState(false);

  useEffect(() => {
    const OpenUrlaubsModal = () => {
      const lastShown = localStorage.getItem('lastShown');
      const today = new Date().toISOString().split('T')[0];
      if (lastShown !== today) {
        setShowUrlaubModal(true);
        localStorage.setItem('lastShown', today);
      }
    };

    const timer = setTimeout(() => {
      if (new Date() < new Date('2024-09-11T00:00:00')) {
        OpenUrlaubsModal();
      }
    }, 5000);

    // clear timer if the component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sidebarIsOpen || cartSidebarIsOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.touchAction = 'none';
      if (isBrowser) document.body.style.paddingRight = '17px';
    } else {
      document.body.style.overflowY = '';
      document.body.style.touchAction = '';
      if (isBrowser) document.body.style.paddingRight = '';
    }
  }, [sidebarIsOpen, cartSidebarIsOpen]);

  useEffect(() => {
    const onCookieAccept = () => {
      if (
        window.CCM.acceptedEmbeddings.some(
          (k) => k.name === 'Google Analytics 4'
        )
      ) {
        ReactGA.gtag('consent', 'update', {
          analytics_storage: 'granted',
        });
      } else {
        ReactGA.gtag('consent', 'update', {
          analytics_storage: 'denied',
        });
      }
    };
    window.addEventListener('ccm19WidgetClosed', onCookieAccept);

    return () =>
      window.removeEventListener('ccm19WidgetClosed', onCookieAccept);
  }, []);

  useEffect(() => {
    const onCookieAccept = ({ detail }) => {
      if (detail.name === 'Google Analytics 4') {
        ReactGA.gtag('consent', 'update', {
          analytics_storage: 'granted',
        });
      }
    };
    window.addEventListener('ccm19EmbeddingAccepted', onCookieAccept);

    return () =>
      window.removeEventListener('ccm19EmbeddingAccepted', onCookieAccept);
  }, []);

  return (
    <div className="body" id="bodydiv">
      <Helmet>
        <script
          async
          defer
          src="https://consenttool.haendlerbund.de/app.js?apiKey=4d62e5cbffce1de5086fbb438f0e9754fa5ff8df8bc3d392&amp;domain=0c61d43"
          referrerpolicy="origin"
        ></script>
      </Helmet>
      {/* <Container fluid="xl" className="g-0"> */}

      {/* Urlaubsmodal */}
      <Modal
        show={showUrlaubsModal}
        onHide={() => setShowUrlaubModal(false)}
        centered
        style={{ background: 'transparent' }}
      >
        <div
          style={{
            backgroundImage: `radial-gradient(
      circle,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(24, 24, 24, 0.5) 75%
    ), url(${UrlaubImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            textShadow: '2px 2px 4px #000000',
            borderRadius: '0.5rem',
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'white' }}>Urlaubshinweis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ color: 'white', textShadow: '2px 2px 4px #000000' }}>
              <p>Liebe Kundinnen und Kunden,</p>
              <p>
                {/* wir sind vom 15. August bis 19. August im Urlaub. Bestellungen,
                die bis Donnerstag den 15. August 12:00 Uhr eingehen, werden
                noch bearbeitet. Ab dem 20. August sind wir dann wieder für euch da*/}
                wir sind vom 30. August bis 10. September im Urlaub.
                Bestellungen, die bis Donnerstag den 29. August eingehen, werden
                noch bearbeitet. Ab dem 11. September sind wir wieder für euch
                da und bearbeiten eure Bestellungen.
              </p>
              <p>Vielen Dank für euer Verständnis!</p>
              <p>Eure BraunBärchen</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="btn_action"
              style={{ textShadow: 'none' }}
              onClick={() => setShowUrlaubModal(false)}
            >
              Verstanden
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Sidebar
        isMenuActive={sidebarIsOpen}
        onOverLayClick={() => {
          setSidebarIsOpen(!sidebarIsOpen);
        }}
      />
      <CartSidebar
        isMenuActive={cartSidebarIsOpen}
        onOverLayClick={() => {
          setCartSidebarIsOpen(!cartSidebarIsOpen);
        }}
      />
      {/* <Row className="g-0">
        <Col className="topbar d-flex justify-content-center align-items-center"> */}

      {/*   </Col>
      </Row>*/}
      <header>
        <ToastContainer position="bottom-center" limit={1} theme="light" />

        <div className={`navbar p-0 w-100`} expand="lg" style={{ top: `0px` }}>
          {/*  <Container fluid="xl"> */}
          {window.innerWidth < 1270 && (
            <div>
              <Marquee speed={35} className="topbar">
                <div>
                  ~ Versandkosten 4,99 € deutschlandweit ~ Kostenlose Abholung
                  in Wipperfürth ~ Zweiwöchiges Rückgaberecht{` `}
                </div>
              </Marquee>
            </div>
          )}
          {isBrowser ? (
            <Row className="w-100 mx-auto g-0 d-flex align-items-end">
              {window.innerWidth >= 1270 && (
                <div className="topbar text-center">
                  ~~~ Versandkosten 4,99 € deutschlandweit ~ Kostenlose Abholung
                  in Wipperfürth ~ Zweiwöchiges Rückgaberecht ~~~
                </div>
              )}
              <Col>
                <Row className="w-100 justify-content-start">
                  <Col className="d-flex justify-content-start">
                    <Nav
                      className="justify-content-end pt-2 pb-3 ps-4 d-flex align-items-center"
                      role="navigation"
                      aria-label="Main menu"
                    >
                      <Button
                        variant="light"
                        onClick={() => {
                          setCartSidebarIsOpen(false);
                          setSidebarIsOpen(!sidebarIsOpen);
                        }}
                        size="sm"
                        color="#000000"
                        className="menuButton"
                        aria-haspopup="menu"
                        id="al"
                        aria-label="Sidebar"
                        aria-controls="main-menu"
                      >
                        <FontAwesomeIcon icon="bars" />
                      </Button>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Link to="/shop/kleidung" className="navbar_category">
                      Kleidung
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex justify-content-center">
                <Link to="/shop/schlafsaecke" className="navbar_category">
                  Schlafsäcke
                </Link>
              </Col>
              <Col
                className="d-flex justify-content-center px-0 col-4"
                style={{ height: isBrowser ? 111 : 71 }}
              >
                <LinkContainer to="/">
                  <Navbar.Brand title="BraunBärchen" href="/" className="mx-0">
                    <img
                      height={isBrowser ? 100 : 60}
                      src="/logoBB.svg"
                      className="d-inline-block align-top"
                      alt="BraunBärchen logo"
                    />
                  </Navbar.Brand>
                </LinkContainer>
              </Col>
              {/* 
              <Col className="d-flex justify-content-center">
                <Link to="/shop/tonies" className="navbar_category">
                  Tonies
                </Link>
              </Col>
              */}
              <Col className="d-flex justify-content-center">
                <Link to="/shop/accessoires" className="navbar_category">
                  Accessoires
                </Link>
              </Col>
              <Col>
                <Row className="w-100 justify-content-end">
                  <Col className="d-flex justify-content-end px-0 col-4">
                    <Nav className="justify-content-end">
                      {authState.isAdmin && (
                        <NavDropdown
                          title="A"
                          id="adminDropdown"
                          className="adminDropdown"
                        >
                          <NavDropdown.Item href="/admin/dashboard">
                            Übersicht
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/tracking">
                            Benutzertracking
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/neuekleidung">
                            Neue Kleidung
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/neuerschlafsack">
                            Neuer Schlafsack
                          </NavDropdown.Item>
                          {/* 
                          <NavDropdown.Item href="/admin/neuertonie">
                            Neuer Tonie
                          </NavDropdown.Item>
                          */}
                          <NavDropdown.Item href="/admin/neuesaccessoires">
                            Neues Accessoires
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/products">
                            Artikelliste
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/prices">
                            Preise und Factoren
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/orderhistory">
                            Bestellhistorie
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/reports">
                            Reports
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/admin/sale">
                            Sale
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => signoutHandler()}>
                            Ausloggen
                          </NavDropdown.Item>
                        </NavDropdown>
                      )}
                      <div
                        onClick={() => {
                          setSidebarIsOpen(false);
                          setCartSidebarIsOpen(!cartSidebarIsOpen);
                        }}
                        className="nav-link pt-2 pb-3 pe-3 d-flex align-items-center"
                        id="card"
                        style={{ cursor: 'pointer' }}
                      >
                        <Badge
                          pill
                          bg="danger"
                          className={`cart-badge ${
                            cart.cartItems.length === 0
                              ? 'cart-badge-hidden'
                              : ''
                          }`}
                        >
                          {cart.cartItems.length}
                        </Badge>

                        <span className="d-block">
                          <BagIcon />
                        </span>
                      </div>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Link to="/mieten" className="navbar_category">
                      Mieten
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="w-100 mx-auto g-0 d-flex align-items-center">
              <Col className="d-flex justify-content-start h-100 align-items-center ps-3 col-4">
                <Button
                  variant="light"
                  onClick={() => {
                    setCartSidebarIsOpen(false);
                    setSidebarIsOpen(!sidebarIsOpen);
                  }}
                  size="sm"
                  color="#000000"
                  className="menuButton"
                >
                  <FontAwesomeIcon icon="bars" />
                </Button>
              </Col>
              <Col
                className="d-flex justify-content-center px-0 col-4"
                style={{ height: isBrowser ? 111 : 71 }}
              >
                <LinkContainer to="/">
                  <Navbar.Brand title="BraunBärchen" href="/" className="mx-0">
                    <img
                      height={isBrowser ? 100 : 60}
                      src="/logoBB.svg"
                      className="d-inline-block align-top"
                      alt="BraunBärchen logo"
                    />
                  </Navbar.Brand>
                </LinkContainer>
              </Col>
              <Col className="d-flex justify-content-end px-0 col-4">
                <Nav className="justify-content-end">
                  {authState.isAdmin && (
                    <NavDropdown
                      title="A"
                      id="adminDropdown"
                      className="adminDropdown"
                    >
                      <NavDropdown.Item href="/admin/dashboard">
                        Übersicht
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/tracking">
                        Benutzertracking
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/neuekleidung">
                        Neue Kleidung
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/neuerschlafsack">
                        Neuer Schlafsack
                      </NavDropdown.Item>
                      {/* 
                          <NavDropdown.Item href="/admin/neuertonie">
                            Neuer Tonie
                          </NavDropdown.Item>
                          */}
                      <NavDropdown.Item href="/admin/neuesaccessoires">
                        Neues Accessoires
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/products">
                        Artikelliste
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/prices">
                        Preise und Factoren
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/orderhistory">
                        Bestellhistorie
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/reports">
                        Reports
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/admin/sale">
                        Sale
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => signoutHandler()}>
                        Ausloggen
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                  <div
                    onClick={() => {
                      setSidebarIsOpen(false);
                      setCartSidebarIsOpen(!cartSidebarIsOpen);
                    }}
                    className="nav-link pe-3 d-flex align-items-center"
                    id="card"
                    style={{ cursor: 'pointer' }}
                  >
                    <Badge
                      pill
                      bg="danger"
                      className={`cart-badge ${
                        cart.cartItems.length === 0 ? 'cart-badge-hidden' : ''
                      }`}
                    >
                      {cart.cartItems.length}
                    </Badge>

                    <span className="d-block">
                      <BagIcon />
                    </span>
                  </div>
                </Nav>
              </Col>
            </Row>
          )}
        </div>
      </header>

      <div
        className="wrapper"
        id="wrapper"
        style={{ top: isBrowser ? '125px' : '80px' }}
      >
        <main>
          <Container fluid="2xl" className="main-container container-fluid g-0">
            <div className="main-container">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense
                  fallback={
                    <Row className="d-flex justify-content-center">
                      <div style={{ position: 'fixed' }}>
                        <LoadingBox />
                      </div>
                    </Row>
                  }
                >
                  <Routes>
                    <Route
                      path="/shop/kleidung"
                      element={
                        <ShopScreen
                          key={shopType.Kleidung}
                          shopType={shopType.Kleidung}
                        />
                      }
                    />
                    <Route
                      path="/shop"
                      element={
                        <ShopScreen
                          key={shopType.Kleidung}
                          shopType={shopType.Kleidung}
                        />
                      }
                    />
                    <Route
                      path="/shop/schlafsaecke"
                      element={
                        <ShopScreen
                          key={shopType.Schlafsack}
                          shopType={shopType.Schlafsack}
                        />
                      }
                    />
                    <Route
                      path="/shop/tonies"
                      /* element={
                      <ShopScreen
                        key={shopType.Tonies}
                        shopType={shopType.Tonies}
                      />
                    } */
                      element={<BaggerScreen />}
                    />
                    <Route
                      path="/mieten"
                      /* element={
                      <ShopScreen
                        key={shopType.Tonies}
                        shopType={shopType.Tonies}
                      />
                    } */
                      element={<BaggerScreen />}
                    />
                    <Route
                      path="/shop/accessoires"
                      element={
                        <ShopScreen
                          key={shopType.Accessoires}
                          shopType={shopType.Accessoires}
                        />
                      }
                    />
                    <Route
                      path="/product/:itemNumber"
                      element={<ProductScreen />}
                    />
                    <Route path="/warenkorb" element={<CartScreen />} />
                    <Route path="/admin/signin" element={<SigninScreen />} />
                    <Route path="/admin/signup" element={<SignupScreen />} />
                    <Route path="/aboutus" element={<AboutUsScreen />} />
                    <Route path="/webapp" element={<WebApp />} />
                    <Route path="/impressum" element={<ImpressumScreen />} />
                    <Route path="/agb" element={<AGBScreen />} />
                    <Route path="/dsgvo" element={<DataProtectionScreen />} />
                    <Route path="/widerruf" element={<RevocationScreen />} />
                    <Route
                      path="/zahlung&versand"
                      element={<PayAndDeliveryScreen />}
                    />
                    <Route
                      path="/bestellung/benutzerdaten"
                      element={<PlaceOrderUserDataScreen />}
                    />
                    <Route
                      path="/bestellung/lieferung"
                      element={<PlaceOrderDeliveryScreen />}
                    />
                    <Route
                      path="/bestellung/bezahlmethode"
                      element={<PlaceOrderPaymentMethodScreen />}
                    />
                    <Route
                      path="/bestellung/uebersicht"
                      element={<PlaceOrderOrderSummaryScreen />}
                    />
                    <Route path="/bestellung/:id" element={<OrderScreen />} />
                    <Route path="/order/:id" element={<OrderScreen />} />
                    <Route path="/verkaufen" element={<BecomeSellerScreen />} />
                    <Route path="/shop/*" element={<></>} />
                    <Route path="/" element={<HomeScreen />} />
                    <Route element={<AdminRoute />}>
                      <Route
                        path="/admin/dashboard"
                        element={<DashboardScreen />}
                      />
                      <Route
                        path="/admin/tracking"
                        element={<TrackingDashboardScreen />}
                      />
                      <Route path="/admin/prices" element={<FactorScreen />} />
                      <Route
                        path="/admin/neuekleidung"
                        element={<NewProductScreen type={shopType.Kleidung} />}
                      />
                      <Route
                        path="/admin/neuerschlafsack"
                        element={
                          <NewProductScreen type={shopType.Schlafsack} />
                        }
                      />
                      <Route
                        path="/admin/neuertonie"
                        element={<NewProductScreen type={shopType.Tonies} />}
                      />
                      <Route
                        path="/admin/neuesaccessoires"
                        element={
                          <NewProductScreen type={shopType.Accessoires} />
                        }
                      />
                      <Route
                        path="/admin/products"
                        element={<ProductListScreen />}
                      />
                      <Route
                        path="/admin/product/:id"
                        element={<ProductEditScreen />}
                      />
                      <Route
                        path="/admin/orderhistory"
                        element={<OrderHistoryScreen />}
                      />
                      <Route path="/admin/reports" element={<ReportScreen />} />
                      <Route path="/admin/sale" element={<SaleScreen />} />
                    </Route>
                    <Route path="*" element={<HomeScreen />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </div>
          </Container>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;

import { useContext, useEffect, useReducer, useState } from 'react';
import '../components/css/homescreen.css';
import '../components/css/index.css';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Carousel,
  CarouselCaption,
  CarouselItem,
  Container,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { isBrowser } from 'react-device-detect';
import useAuth from 'utils/utils';
import SeoUpdater from 'components/SeoUpdater';
import { useInView } from 'react-intersection-observer';
import NewBees from 'components/NewBees';
import Reviews from 'components/Reviews';
import LoadingBox from 'components/LoadingBox';
import ProductModal from 'components/modals/ProductModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Store } from 'Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  //useLazyLoadCSS('../components/css/index.css');

  const headerTags = {
    title: 'Herzlichen Willkommen',
    keywords:
      'Secondhand, preloved, kidsstuff, Kindermode, Home, Willkommen, newbees, Neuheiten, Wipperfürth, Oberberg',
  };

  const navigate = useNavigate();

  const authState = useAuth();

  const [{ loading, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
  });

  const [selectedProduct, setSelectedProduct] = useState();
  const [showProductModal, setShowProductModal] = useState(false);
  const [loadingSelectedProduct, setLoadingSelectedProduct] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`/api/products/newbees`);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  const MainContent = () => {
    return (
      <>
        <Row className="g-0 py-5">
          <Col className="w-100 d-flex justify-content-center">
            <NewBees
              products={products}
              ReactGA={ReactGA}
              setSelectedProduct={setSelectedProduct}
              setLoadingSelectedProduct={setLoadingSelectedProduct}
            />
          </Col>
        </Row>
        <Row className="g-0 py-5" style={{ background: 'rgb(242 255 255)' }}>
          <Col className="w-100 d-flex justify-content-center">
            <NavToShops />
          </Col>
        </Row>
        <Row className="g-0 py-5">
          <Col className="w-100 d-flex justify-content-center">
            <Reviews />
          </Col>
        </Row>
        <Row className="my-3 g-0 w-100"> </Row>
      </>
    );
  };

  const NavToShops = () => {
    const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0,
      triggerOnce: true,
    });

    return (
      <div ref={ref}>
        {inView ? (
          <>
            <Row className="mb-3 g-0 w-100">
              <Col className="d-flex justify-content-center headline_highlights position-relative font-poppins">
                <h1>Hier geht's zum Shop</h1>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col
                className="d-flex justify-content-center"
                style={{ flexFlow: 'row wrap' }}
              >
                <div
                  className="btn_action mx-2 mb-3"
                  onClick={() => navigate('/shop/accessoires')}
                >
                  Accessoires
                </div>
                <div
                  className="btn_action mx-2 mb-3"
                  onClick={() => navigate('/shop/kleidung')}
                >
                  Kleidung
                </div>
                <div
                  className="btn_action mx-2 mb-3"
                  onClick={() => navigate('/shop/schlafsaecke')}
                >
                  Schlafsäcke
                </div>

                {/*
                      <div
                        className="btn_action mx-2 mb-3"
                        onClick={() => navigate('/shop/tonies')}
                      >
                        Tonies
                      </div>
                      */}
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <SeoUpdater headerTags={headerTags} />
      <Container
        fluid
        className="p-0"
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        <Row className="mb-5 mx-0 g-0" style={{ overflowX: 'hidden' }}>
          <Col className="w-100 p-0">
            <Carousel className="p-0 homescreen_carousel" controls={false}>
              {/* <CarouselItem
                className="homescreen_carousel_item"

                // style={{
                //   backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%), url(https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/kids-take-photo${
                //     isBrowser ? `.webp` : `-mobile.webp)`
                //   }`,
                //   backgroundSize: 'cover',
                //   backgroundPosition: 'left top',
                // }}
              >
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={`https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/sommer-urlaub-10-prozent-auf-alles.webp`}
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                    maxWidth: '100%',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.2) 25%, rgba(24, 24, 24, 0.4) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_1"
                  style={{ zIndex: 2 }}
                >
                  <div>
                    <Stack gap={3}>
                      Wir sind vom 30.08.24 bis einschließlich 10.09.24 im
                      Urlaub.
                      <br /> Um euch die Wartezeit etwas zu versüßen, gibt es{' '}
                      10% Rabatt auf das gesamte Sortiment!
                      <div
                        className="btn_action"
                        onClick={() => navigate('/shop/kleidung')}
                      >
                        Schnell zugreifen
                      </div>
                    </Stack>
                  </div>
                </Carousel.Caption>
              </CarouselItem> */}
              <CarouselItem
                className="homescreen_carousel_item"

                // style={{
                //   backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%), url(https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/kids-take-photo${
                //     isBrowser ? `.webp` : `-mobile.webp)`
                //   }`,
                //   backgroundSize: 'cover',
                //   backgroundPosition: 'left top',
                // }}
              >
                <LazyLoadImage
                  alt="Kinder Secondhand Kleidung"
                  effect="blur"
                  src={`https://braunbaerchenproductimages.s3.eu-central-1.amazonaws.com/home/kids-take-photo${
                    isBrowser ? `.webp` : `-mobile.webp`
                  }`}
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                    maxWidth: '100%',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage:
                      'radial-gradient(circle, rgba(0, 0, 0, 0.3) 25%, rgba(24, 24, 24, 0.5) 75%)',
                    zIndex: 1,
                  }}
                ></div>

                <Carousel.Caption
                  className="homescreen_carousel_caption homescreen_carousel_caption_1"
                  style={{ zIndex: 2 }}
                >
                  <div>
                    <Stack gap={3}>
                      Ausgewählte Kinder Secondhand Kleidung zu einem fairen
                      Preis in den größen 50 bis 128
                      <div
                        className="btn_action"
                        onClick={() => navigate('/shop/kleidung')}
                      >
                        Finde neue Lieblingsstücke
                      </div>
                    </Stack>
                  </div>
                </Carousel.Caption>
              </CarouselItem>
              <CarouselItem className="homescreen_carousel_item homescreen_carousel_item_2">
                <CarouselCaption className="homescreen_carousel_caption homescreen_carousel_caption_2">
                  <div className="w-100">
                    <Stack gap={3}>
                      Spare Zeit, Geld und shoppe nachhaltig
                      <Row className="w-100 d-flex justify-content-center">
                        <div
                          className="btn_action"
                          onClick={() => navigate('/shop/schlafsaecke')}
                        >
                          Hier geht's zu den Schlafsäcken
                        </div>
                      </Row>
                    </Stack>
                  </div>
                </CarouselCaption>
              </CarouselItem>
              <CarouselItem className="homescreen_carousel_item homescreen_carousel_item_3">
                <CarouselCaption className="homescreen_carousel_caption homescreen_carousel_caption_3">
                  <div className="w-100">
                    <Stack gap={3}>
                      Mieten statt kaufen spart nicht nur Geld
                      <br />
                      sondern auch Platz und schafft Abwechslung
                      <Row className="w-100 d-flex justify-content-start">
                        <div className="position-relative btn_action">
                          Jetzt Mietsachen entdecken
                          <span
                            className="coming-soon"
                            style={{ top: '-0.8em', right: '-1.5em' }}
                          >
                            Coming soon
                          </span>
                        </div>
                      </Row>
                    </Stack>
                  </div>
                </CarouselCaption>
              </CarouselItem>
            </Carousel>
          </Col>
        </Row>
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <div
              className={`modal-backdrop ${
                loadingSelectedProduct ? 'd-block' : 'd-none'
              }`}
              style={{ opacity: 0.5 }}
            >
              <div
                className={`d-flex justify-content-center h-100 w-100 align-items-center`}
              >
                <LoadingBox />
              </div>
            </div>
            {selectedProduct && (
              <>
                <ProductModal
                  product={selectedProduct}
                  authState={authState}
                  modalProps={{
                    show: showProductModal,
                    onHide: () => {
                      setShowProductModal(false);
                      setSelectedProduct(undefined);
                    },
                    fullscreen: !isBrowser,
                  }}
                />
                <img
                  src={selectedProduct.image}
                  alt={`${selectedProduct.brand}-${selectedProduct.size}-${selectedProduct.category}`}
                  onLoad={() => {
                    setLoadingSelectedProduct(false);
                    setShowProductModal(true);
                  }}
                  className="d-none"
                />
              </>
            )}
            <MainContent />
          </>
        )}
      </Container>
    </div>
  );
}

export default HomeScreen;

import axios from 'axios';
import UserCard from 'components/UserCard';
import { useContext, useEffect, useState } from 'react';
import { Store } from 'Store';

const TrackingDashboardScreen = () => {
  const [users, setUsers] = useState([]);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const fetchData = async () => {
    const { data } = await axios.get('/api/users/interactions', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    setUsers(data.filter((user) => !excludedUsers.includes(user._id)));
  };

  useEffect(() => {
    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, 5000); // Fetch every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const excludedUsers = [
    '64f8515b-676b-4bf2-9004-cea479306496',
    'b687e1d6-d1ea-4c41-a52f-1b344b3bc1bf',
    '9f6b9f1d-92a8-4ee6-8231-c773dfa38fe0',
    'c7bef466-5264-431d-834d-0734e52b3450',
    'af4699cd-5688-407e-a646-5ee1e61fdcd1',
  ];

  return (
    <>
      <h5 className="ms-2">{`Zeitraum: 30 Minuten`}</h5>
      <div className="ms-2">{`Aktive Nutzer: ${users.length}`}</div>
      <div>
        {users.map((user) => (
          <UserCard key={user._id} user={user} />
        ))}
      </div>
    </>
  );
};

export default TrackingDashboardScreen;

export const deliveryEnum = {
  Abholen: 'abholen',
  LiefernInnerhalb: 'liefernInnerhalb',
  LiefernDHL: 'liefernDHL',
};

export const paymentMethodEnum = {
  Barzahlung: {
    key: 'Barzahlung',
    value: 'Barzahlung',
  },
  PayPal: {
    key: 'PayPal',
    value: 'PayPal',
    button: 'paypal',
  },
  Klarna: {
    key: 'Klarna',
    value: 'Klarna',
  },
  Lastschrift: {
    key: 'PayPal',
    value: 'Lastschrift',
    button: 'sepa',
  },
  Giropay: {
    key: 'PayPal',
    value: 'Giropay',
    button: 'giropay',
  },
  DebitKreditcart: {
    key: 'PayPal',
    value: 'Debit- & Kreditkarte',
    button: 'card',
  },
};

export const deliveryCosts = {
  [deliveryEnum.Abholen]: 0.0,
  [deliveryEnum.LiefernInnerhalb]: 2.5,
  [deliveryEnum.LiefernDHL]: 4.99,
};

export const shopType = {
  Kleidung: 'Kleidung',
  Schlafsack: 'Schlafsack',
  Tonies: 'Tonies',
  Accessoires: 'Accessoires',
};

export const conditionType = {
  SehrGut: 'Sehr gut',
  Gut: 'Gut',
  Geliebt: 'Geliebt',
};

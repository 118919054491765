import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import { useInView } from 'react-intersection-observer';
import Product from './Product';

const NewBees = (props) => {
  const { products, ReactGA, setSelectedProduct, setLoadingSelectedProduct } =
    props;

  const [mousePosition, _setMousePosition] = useState({ x: null, y: null });
  const mousePositionRef = useRef(mousePosition);
  const setMousePosition = (value) => {
    mousePositionRef.current = value;
    _setMousePosition(value);
  };

  const [startMouseX, setStartMouseX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [currentDragOffset, setCurrentDragOffset] = useState(0);

  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  useEffect(() => {
    const disableDragGhostImage = (event) => {
      event.dataTransfer.setDragImage(new Image(), 0, 0);
    };

    document.addEventListener('dragstart', disableDragGhostImage, false);

    return () => {
      window.removeEventListener('mousemove', disableDragGhostImage);
    };
  }, []);

  useEffect(() => {
    const elements = [...document.getElementsByClassName('rfm-marquee')].filter(
      (e) => e.parentElement.className.includes('highlights')
    );

    if (elements.length > 0) {
      elements.forEach((element) => {
        element.style.setProperty(
          '--delay',
          (dragOffset + currentDragOffset) / 35 + 's'
        );
      });
    }
  }, [mousePosition, currentDragOffset, dragOffset]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView ? (
        <>
          <Row>
            <Col className="d-flex justify-content-center headline_highlights position-relative font-poppins">
              <h1>Neu im Shop</h1>
            </Col>
          </Row>
          <Row style={{ userSelect: 'none' }}>
            <Col
              draggable={true}
              className="m-0 p-0 g-0 d-flex"
              style={{ maxWidth: '99vw' }}
              onDragEnter={(e) => {
                setStartMouseX(mousePosition.x);
              }}
              onDrag={(e) => {
                e.clientX !== 0 &&
                  setCurrentDragOffset(e.clientX - startMouseX);
              }}
              onDragEnd={() => {
                setDragOffset(dragOffset + currentDragOffset);
                setCurrentDragOffset(0);
              }}
              onTouchStart={(e) => {
                setStartMouseX(e.touches[0].clientX);
              }}
              onTouchMove={(e) => {
                setCurrentDragOffset(e.touches[0].clientX - startMouseX);
              }}
              onTouchEnd={() => {
                setDragOffset(dragOffset + currentDragOffset);
                setCurrentDragOffset(0);
              }}
            >
              <Marquee
                play={true}
                speed={35}
                className="marquee_highlights_container m-0"
                pauseOnClick
              >
                {products.map((product) => (
                  <div className="d-flex marquee_highlights" key={product._id}>
                    <Card
                      className="m-1 productcard d-flex align-items-stretch"
                      border="light"
                    >
                      <Product
                        product={product}
                        onSelect={() => {
                          ReactGA.event(
                            `Artikel öffnen - ${product.category} - ${product.size} - ${product.brand}`,
                            { ...product }
                          );
                          setSelectedProduct(product);
                          setLoadingSelectedProduct(true);
                        }}
                        //loadLazy={true}
                      ></Product>
                    </Card>
                  </div>
                ))}
              </Marquee>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default NewBees;

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Col, Row } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import './css/reviews.css';
import { FaGoogle, FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { Store } from 'Store';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [expandedReviews, setExpandedReviews] = useState({});

  const { state } = useContext(Store);
  const { id, cart } = state;

  useEffect(() => {
    axios
      .get('/api/google/reviews/', {
        headers: {
          userid: id,
          cart: cart.cartItems.map((c) => c._id) || [],
        },
      })
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error('Error fetching reviews:', error);
      });
  }, []);

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={`full-${i}`} className="star" />);
    }
    if (halfStar) {
      stars.push(<FaStarHalfAlt key="half" className="star" />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={`empty-${i}`} className="star" />);
    }
    return stars;
  };

  const handleMoreClick = (reviewId) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId],
    }));
  };

  const truncateText = (text, reviewId, length) => {
    if (text.length <= length || expandedReviews[reviewId]) return text;
    return (
      <>
        {text.substring(0, length)}...
        <span
          className="text-primary"
          style={{ cursor: 'pointer' }}
          onClick={() => handleMoreClick(reviewId)}
        >
          {' '}
          mehr
        </span>
      </>
    );
  };

  return (
    <>
      {reviews.length > 0 && (
        <div className="mt-5">
          <Row>
            <Col className="d-flex justify-content-center headline_highlights position-relative font-poppins">
              <h1>Was unsere Kunden sagen</h1>
            </Col>
          </Row>
          <Carousel>
            {reviews.map((review, index) => (
              <Carousel.Item key={index} className="review-carousel-item">
                <div className="d-flex justify-content-center">
                  <div className="review-card p-4">
                    <div className="review-card-body">
                      <div className="d-flex align-items-center mb-2">
                        <img
                          src={review.reviewer.profilePhotoUrl}
                          alt={review.reviewer.displayName}
                          className="review-profile-pic me-3"
                        />
                        <div>
                          <h5 className="review-card-title">
                            {review.reviewer.displayName}
                          </h5>
                          <p className="review-card-time text-muted">
                            {formatDistanceToNow(new Date(review.createTime), {
                              addSuffix: true,
                              locale: de,
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">{renderStars(5)}</div>
                      <p className="review-card-text">
                        {truncateText(review.comment, review.reviewId, 200)}
                      </p>
                      <div className="text-center mt-3">
                        <FaGoogle className="google-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default Reviews;

import '../css/productmodal.css';
import axios from 'axios';
import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Stack from 'react-bootstrap/Stack';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import { isBrowser } from 'react-device-detect';
import { Store } from 'Store';
import ReactGA from 'react-ga4';
import { conditionType } from 'utils/types';

function ProductModal(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { product, authState, modalProps } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
    reserved,
    id,
  } = state;

  const [showConditionModal, setShowConditionModal] = useState(false);

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    if (existItem) {
      toast.info('Der Artikel ist bereits im Warenkorb.', { draggable: false });
      return;
    }
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.reservedUntil && new Date(data.reservedUntil) > new Date()) {
      toast.error(
        'Oh da war jemand leider schneller. Der Artikel ist nicht mehr verfügbar.',
        { draggable: false }
      );
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item },
    });
    ReactGA.event(
      `Artikel hinzufügen - ${product.category} - ${product.size} - ${product.brand}`,
      { ...product }
    );
    await axios.get('/api/users/addproduct', {
      headers: {
        userid: id,
        cart: cartItems.map((c) => c._id),
        page: `Artikel`,
        productid: item._id,
      },
    });
  };

  const customStyling = `
    .flying_image {
      --target-position-x: 0px;
      --target-position-y: 0px;
      --target-endposition-x: 0px;
      --target-endposition-y: 0px;
    
      display: block;
      position: fixed;
      top: var(--target-position-y);
      left: var(--target-position-x);
      translate: -50% -50%;
      animation: fly 1.5s 1;
      z-index: 100;
    }
    @keyframes fly {
      0% {
        top: var(--target-position-y);
        left: var(--target-position-x);
        opacity: 1;
      }
      100% {
        top: var(--target-endposition-y);
        left: var(--target-endposition-x);
        opacity: 0;
        scale: 0.1;
      }
    }`;

  const flyingImage = useRef(null);

  const initFlight = (e, src) => {
    const target = document.getElementById('card').getBoundingClientRect();
    const productImg = document
      .getElementById('productImg')
      .getBoundingClientRect();

    flyingImage.current.style.setProperty(
      '--target-position-x',
      productImg.left + productImg.width / 2 + 'px'
    );
    flyingImage.current.style.setProperty(
      '--target-position-y',
      productImg.top + productImg.height / 2 + 'px'
    );
    flyingImage.current.style.setProperty(
      '--target-endposition-x',
      target.left + 40 + 'px'
    );
    flyingImage.current.style.setProperty(
      '--target-endposition-y',
      target.top + 20 + 'px'
    );
    flyingImage.current.style.setProperty('display', '');
    flyingImage.current.src = src;
    setTimeout(() => {
      try {
        flyingImage && flyingImage.current.style.setProperty('display', 'none');
      } catch (error) {}
    }, 1.5 * 1000 - 100);
  };

  const ConditionBody = () => {
    return (
      <ListGroup
        variant="flush"
        className="w-100"
        style={{ borderBottom: '0' }}
      >
        <ListGroup.Item>
          <div>
            <strong>{conditionType.SehrGut}</strong>
          </div>
          <div>
            Ich habe, wenn überhaupt, so minimale Gebrauchsspuren, dass ich als
            „ungetragen“ durchgehen könnte.
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div>
            <strong>{conditionType.Gut}</strong>
          </div>
          <div>
            Ich habe minimale Gebrauchsspuren, wie z. B. leichter Formverzug vom
            Waschen, minimales Waschpilling o. Ä.
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div>
            <strong>{conditionType.Geliebt}</strong>
          </div>
          <div>
            Ich wurde getragen, bespielt und geliebt, habe aber trotzdem keine
            Löcher oder Flecken. Leichter Knieabrieb, Waschpilling oder Risse im
            Druck können mich auszeichnen.
          </div>
        </ListGroup.Item>
      </ListGroup>
    );
  };

  const popoverCondition = (
    <Popover id="popover-condition">
      <Popover.Header as="h3">Zustand</Popover.Header>
      <Popover.Body className="p-0">
        <ConditionBody />
      </Popover.Body>
    </Popover>
  );

  function ConditionModal(props) {
    return (
      <Modal {...props} size="md">
        <Modal.Header closeButton style={{ backgroundColor: '#e9ecef' }}>
          <Modal.Title>Zustand</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ConditionBody />
        </Modal.Body>
      </Modal>
    );
  }

  const checkoutHandler = async () => {
    modalProps.onHide();

    if (await checkCartHandler()) {
      checkCartAndReserve().then((reserve) => {
        if (reserve.status === 200) {
          ctxDispatch({
            type: 'RESERVED_PRODUCTS',
            payload: {
              IDs: cartItems.map((p) => p._id),
              reservedUntil: new Date(reserve.data),
            },
          });
          navigate('/bestellung/benutzerdaten', {
            state: { prevPage: pathname },
          });
        }
      });
    }
  };

  const checkCartAndReserve = async () => {
    try {
      // Reservieren
      const reserve = await axios.post(`/api/products/reserveproducts`, {
        params: {
          productids: cartItems.map((p) => p._id),
          reservedUntil: new Date().getTime() + 15 * 60 * 1000,
        },
      });
      return reserve;
    } catch (error) {
      toast.error('Das hat leider nicht funktioniert. Probiere es erneut!', {
        draggable: false,
      });
      return error.response;
    }
  };

  async function checkCartHandler() {
    let success = true;

    const { data } = await axios.get(`/api/products/checkcart`, {
      params: {
        productids: cartItems.map((p) => p._id),
      },
    });

    if (data.soldProducts.length > 0) {
      success = false;
      data.soldProducts.forEach((item) => {
        let cartProduct = cartItems.find((p) => p._id === item._id);
        toast.error(
          <div>
            <div>{`Da war leider jemand schneller :(`}</div>
            <img
              className="img-fluid rounded img-thumbnail"
              src={cartProduct.image?.replace('.png', '_lowres.png')}
              alt={`${cartProduct.category}-${cartProduct.size}-${cartProduct.brand}`}
            />
            <div>
              {`Der Artikel ${cartProduct.category} | ${cartProduct.size} | ${cartProduct.brand} ist nicht mehr verfügbar.`}
            </div>
          </div>,
          { draggable: false }
        );
        let removedProduct = document.getElementById(item._id);
        removedProduct.style.opacity = '0';
      });
    }

    if (data.notFound.length > 0) {
      success = false;
      data.notFound.forEach((id) => {
        let cartProduct = cartItems.find((p) => p._id === id);
        toast.error(
          <div>
            <div>{`Da war leider jemand schneller :(`}</div>
            <img
              className="img-fluid rounded img-thumbnail"
              src={cartProduct.image?.replace('.png', '_lowres.png')}
              alt={`${cartProduct.category}-${cartProduct.size}-${cartProduct.brand}`}
            />
            <div>
              {`Der Artikel ${cartProduct.category} | ${cartProduct.size} | ${cartProduct.brand} ist nicht mehr verfügbar.`}
            </div>
          </div>,
          { draggable: false }
        );
        let removedProduct = document.getElementById(id);
        removedProduct.style.opacity = '0';
      });
    }

    if (data.products.length > 0) {
      data.products.forEach((item) => {
        let cartProduct = cartItems.find((p) => p._id === item._id);
        if (
          item.price !== cartProduct.price ||
          item.discount !== cartProduct.discount ||
          item.sale !== cartProduct.sale
        ) {
          success = false;
          toast.info(
            <div>
              <div>{`Der Preis für den Artikel ${cartProduct.itemNumber} wurde aktualisiert.`}</div>
              <img
                className="img-fluid rounded img-thumbnail"
                src={cartProduct.image?.replace('.png', '_lowres.png')}
                alt={`${cartProduct.category}-${cartProduct.size}-${cartProduct.brand}`}
              />
              <div>{`Neuer Preis: ${item.price.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
              })}`}</div>
            </div>,
            { draggable: false }
          );
        } else if (
          new Date(item.reservedUntil) > new Date() &&
          !reserved.IDs.some((p) => p === item._id)
        ) {
          success = false;
          toast.error(
            <div>
              <div>{`Da war leider jemand schneller :(`}</div>
              <img
                className="img-fluid rounded img-thumbnail"
                src={cartProduct.image?.replace('.png', '_lowres.png')}
                alt={`${cartProduct.category}-${cartProduct.size}-${cartProduct.brand}`}
              />
              <div>
                {`Der Artikel ${cartProduct.category} | ${cartProduct.size} | ${cartProduct.brand} ist bereits reserviert.`}
              </div>
            </div>,
            { draggable: false }
          );
          let removedProduct = document.getElementById(item._id);
          removedProduct.style.opacity = '0';
        }
      });
    }

    if (!success) {
      setTimeout(() => {
        ctxDispatch({
          type: 'CART_UPDATE_ITEMS',
          payload: { data },
        });
      }, 2000);
      return false;
    } else {
      return true;
    }
  }

  return (
    <Modal
      {...modalProps}
      dialogClassName={`mw-100${isBrowser ? ` w-75` : ``}`}
      centered
    >
      <Modal.Body>
        <div>
          <ConditionModal
            show={showConditionModal}
            onHide={() => setShowConditionModal(false)}
          />
          <Row>
            <Helmet>
              <title>
                {`Artikeldetails - ${product.category} - ${product.size} - ${product.brand}`}
              </title>
            </Helmet>

            <Col md={{ offset: 1, span: 4 }} className="px-3">
              {product.sale && (
                <div
                  className="position-absolute p-3"
                  style={{ zIndex: '5', fontSize: '1.25rem' }}
                >
                  <span className="sale-tag px-2 py-3">{`-${product.discount}%`}</span>
                </div>
              )}
              {!isBrowser && (
                <div className="w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => modalProps.onHide()}
                  />
                </div>
              )}
              {product.images.length > 0 ? (
                <Carousel variant="dark mb-3" controls={false}>
                  <CarouselItem>
                    <img
                      src={`${product.image}`}
                      className="w-100 mb-3 product__image"
                      alt={product.name}
                      id="productImg"
                    />
                  </CarouselItem>
                  {product.images.map((i) => {
                    return (
                      <CarouselItem key={i}>
                        <img
                          src={`${i}`}
                          className="w-100 mb-3 product__image"
                          alt={product.name}
                        />
                      </CarouselItem>
                    );
                  })}
                </Carousel>
              ) : (
                <img
                  src={`${product.image}`}
                  className="w-100 mb-3 product__image"
                  alt={product.name}
                  id="productImg"
                />
              )}
            </Col>
            <Col md={{ offset: 1, span: 5 }} className="g-0">
              <Stack gap={4} className="d-flex justify-content-center mb-5">
                <ListGroup variant="flush" className="cartList">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <Table borderless size="sm" className="productTable">
                          <thead>
                            <tr>
                              <th />
                              <th />
                              <th />
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>Art:</td>
                              <td>{product.category}</td>
                            </tr>
                            <tr>
                              <td>Marke:</td>
                              <td>{product.brand}</td>
                            </tr>
                            <tr>
                              <td>
                                Zustand:{' '}
                                {true ? (
                                  <OverlayTrigger
                                    overlay={popoverCondition}
                                    placement={isBrowser ? 'right' : 'top'}
                                  >
                                    <FontAwesomeIcon icon="circle-info" />
                                  </OverlayTrigger>
                                ) : (
                                  <span
                                    onClick={() => setShowConditionModal(true)}
                                  >
                                    <FontAwesomeIcon icon="circle-info" />
                                  </span>
                                )}
                              </td>
                              <td>{product.condition}</td>
                            </tr>
                            {product.description !== '' && (
                              <tr>
                                <td>Info:</td>
                                <td>{product.description}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Größe:</td>
                              <td>
                                {product.sizeHat &&
                                product.sizeHat.length > 0 ? (
                                  <>
                                    {product.sizeHat.length >= 2 ? (
                                      <div>
                                        Hutgr.{` `}
                                        {Math.min(...product.sizeHat)} -{' '}
                                        {Math.max(...product.sizeHat)}
                                      </div>
                                    ) : (
                                      <div>
                                        Hutgr.{` `}
                                        {product.sizeHat[0]}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>{product.size}</>
                                )}
                              </td>
                              <td className="h6 text-end">
                                {product.sale ? (
                                  <>
                                    <span
                                      style={{
                                        textDecoration: 'line-through',
                                        color: '#777777',
                                      }}
                                    >
                                      {product.price.toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      })}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {product.price.toLocaleString('de-DE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    })}
                                  </>
                                )}
                              </td>
                            </tr>
                            {product.sale && (
                              <tr>
                                <td></td>
                                <td></td>
                                <td
                                  className="h6 text-end"
                                  style={{ color: 'red' }}
                                >
                                  {(
                                    Math.round(
                                      product.price *
                                        (1 - product.discount / 100) *
                                        1e2
                                    ) / 1e2
                                  ).toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        {/* <Stack gap={0}>
                      <div className="fw-bold">{product.category}</div>
                      <div>{product.brand}</div>
                      <div style={{ minHeight: '1.5rem' }}>
                        {product.description}
                      </div>
                      <div className="text_cart__size">Gr. {product.size}</div>
                    </Stack>*/}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
                <div className="d-flex justify-content-center">
                  <div
                    className="d-flex justify-content-center"
                    onClick={(e) => {
                      !cartItems.find((x) => x._id === product._id) &&
                        !(
                          product.reservedUntil &&
                          new Date(product.reservedUntil) > new Date()
                        ) &&
                        initFlight(e, product.image);
                    }}
                  >
                    <style>{customStyling}</style>
                    <img
                      src=""
                      alt=""
                      className="flying_image"
                      style={{
                        display: 'none',
                        ...{ borderRadius: '8rem', width: '16rem' },
                        ...{ borderRadius: '8rem', width: '16rem' },
                      }}
                      ref={flyingImage}
                    />
                    <div
                      onClick={() => {
                        !cartItems.find((x) => x._id === product._id) &&
                          !(
                            product.reservedUntil &&
                            new Date(product.reservedUntil) > new Date()
                          ) &&
                          addToCartHandler(product);
                      }}
                      className={`btn_action btn_cart position-relative ${
                        cartItems.find((x) => x._id === product._id) ||
                        (product.reservedUntil &&
                          new Date(product.reservedUntil) > new Date())
                          ? 'btn_action_disabled'
                          : ''
                      }`}
                      //style={{ fontSize: '1rem', height: '2.5rem' }}
                    >
                      <span className="abs-center addToCart_cart">
                        <FontAwesomeIcon icon="cart-plus" size="lg" />
                      </span>
                      <div
                        className={`addToCart_text ${
                          cartItems.find((x) => x._id === product._id)
                            ? 'addToCart_text_added'
                            : ''
                        }`}
                      >
                        {cartItems.find((x) => x._id === product._id)
                          ? 'Bereits im Warenkorb'
                          : product.reservedUntil &&
                            new Date(product.reservedUntil) > new Date()
                          ? `Bereits reserviert`
                          : `In den Warenkorb`}
                      </div>
                    </div>
                  </div>
                </div>
                {cartItems.length > 0 && (
                  <Row className="d-flex justify-content-center mb-5 g-0">
                    <Col className="d-flex justify-content-center w-100">
                      <div
                        onClick={() => checkoutHandler()}
                        disabled={cartItems.length === 0}
                        className="btn_action"
                      >
                        {`Zur Kasse`}
                      </div>
                    </Col>
                  </Row>
                )}
                {authState.isAdmin && (
                  <div className="d-flex justify-content-center w-100">
                    <div
                      onClick={() => {
                        navigate(`/admin/product/${product._id}`);
                        modalProps.onHide();
                      }}
                      className="btn_action mx-5"
                    >
                      {`Bearbeiten`}
                    </div>
                  </div>
                )}
              </Stack>
            </Col>
            {isBrowser && (
              <Col xs={1} className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => modalProps.onHide()}
                />
              </Col>
            )}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ProductModal;

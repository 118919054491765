import React, { useContext, useEffect, useReducer, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, ListGroup, Modal, Row, Stack } from 'react-bootstrap';
import { Store } from 'Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import LoadingBox from './LoadingBox';
import { getSizeText } from 'utils/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, loading: true };
    case 'SUCCESS':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export function CartSidebar(props) {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
    reserved,
    id,
  } = state;

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showRemoveProductModal, setShowRemoveProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const { isMenuActive, onOverLayClick } = props;

  const sideMenuClasses = classnames('side-menu side-menu-right', {
    'side-menu--active': isMenuActive,
    'side-menu-right--active': isMenuActive,
  });
  const sideMenuContentClasses = classnames(
    'side-menu__content side-menu-right__content',
    {
      'side-menu__content--active': isMenuActive,
      'side-menu-right__content--active': isMenuActive,
    }
  );

  const checkoutHandler = async () => {
    props.onOverLayClick();
    dispatch({ type: 'REQUEST' });
    if (await checkCartHandler()) {
      checkCartAndReserve().then((reserve) => {
        if (reserve.status === 200) {
          ctxDispatch({
            type: 'RESERVED_PRODUCTS',
            payload: {
              IDs: cartItems.map((p) => p._id),
              reservedUntil: new Date(reserve.data),
            },
          });
          navigate('/bestellung/benutzerdaten', {
            state: { prevPage: pathname },
          });
        }
      });
    }
  };

  const checkCartAndReserve = async () => {
    try {
      // Reservieren
      const reserve = await axios.post(`/api/products/reserveproducts`, {
        params: {
          productids: cartItems.map((p) => p._id),
          reservedUntil: new Date().getTime() + 15 * 60 * 1000,
        },
      });
      return reserve;
    } catch (error) {
      toast.error('Das hat leider nicht funktioniert. Probiere es erneut!', {
        draggable: false,
      });
      return error.response;
    }
  };

  async function checkCartHandler() {
    dispatch({ type: 'REQUEST' });

    let success = true;

    const { data } = await axios.get(`/api/products/checkcart`, {
      params: {
        productids: cartItems.map((p) => p._id),
      },
    });

    if (data.soldProducts.length > 0) {
      success = false;
      data.soldProducts.forEach((item) => {
        let cartProduct = cartItems.find((p) => p._id === item._id);
        toast.error(
          <div>
            <div>{`Da war leider jemand schneller :(`}</div>
            <img
              className="img-fluid rounded img-thumbnail"
              src={cartProduct.image?.replace('.png', '_lowres.png')}
              alt={`${cartProduct.category}-${getSizeText(cartProduct)}-${
                cartProduct.brand
              }`}
            />
            <div>
              {`Der Artikel ${cartProduct.category} | ${getSizeText(
                cartProduct
              )} | ${cartProduct.brand} ist nicht mehr verfügbar.`}
            </div>
          </div>,
          { draggable: false }
        );
        let removedProduct = document.getElementById(item._id);
        removedProduct.style.opacity = '0';
      });
    }

    if (data.notFound.length > 0) {
      success = false;
      data.notFound.forEach((id) => {
        let cartProduct = cartItems.find((p) => p._id === id);
        toast.error(
          <div>
            <div>{`Da war leider jemand schneller :(`}</div>
            <img
              className="img-fluid rounded img-thumbnail"
              src={cartProduct.image?.replace('.png', '_lowres.png')}
              alt={`${cartProduct.category}-${getSizeText(cartProduct)}-${
                cartProduct.brand
              }`}
            />
            <div>
              {`Der Artikel ${cartProduct.category} | ${getSizeText(
                cartProduct
              )} | ${cartProduct.brand} ist nicht mehr verfügbar.`}
            </div>
          </div>,
          { draggable: false }
        );
        let removedProduct = document.getElementById(id);
        removedProduct.style.opacity = '0';
      });
    }

    if (data.products.length > 0) {
      data.products.forEach((item) => {
        let cartProduct = cartItems.find((p) => p._id === item._id);
        if (
          item.price !== cartProduct.price ||
          item.discount !== cartProduct.discount ||
          item.sale !== cartProduct.sale
        ) {
          success = false;
          toast.info(
            <div>
              <div>{`Der Preis für den Artikel ${cartProduct.itemNumber} wurde aktualisiert.`}</div>
              <img
                className="img-fluid rounded img-thumbnail"
                src={cartProduct.image?.replace('.png', '_lowres.png')}
                alt={`${cartProduct.category}-${getSizeText(cartProduct)}-${
                  cartProduct.brand
                }`}
              />
              <div>{`Neuer Preis: ${item.price.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
              })}`}</div>
            </div>,
            { draggable: false }
          );
        } else if (
          new Date(item.reservedUntil) > new Date() &&
          !reserved.IDs.some((p) => p === item._id)
        ) {
          success = false;
          toast.error(
            <div>
              <div>{`Da war leider jemand schneller :(`}</div>
              <img
                className="img-fluid rounded img-thumbnail"
                src={cartProduct.image?.replace('.png', '_lowres.png')}
                alt={`${cartProduct.category}-${cartProduct.size}-${cartProduct.brand}`}
              />
              <div>
                {`Der Artikel ${cartProduct.category} | ${getSizeText(
                  cartProduct
                )} | ${cartProduct.brand} ist bereits reserviert.`}
              </div>
            </div>,
            { draggable: false }
          );
          let removedProduct = document.getElementById(item._id);
          removedProduct.style.opacity = '0';
        } else if (item.sale !== cartProduct.sale) {
          success = false;
        }
      });
    }

    if (!success) {
      setTimeout(() => {
        ctxDispatch({
          type: 'CART_UPDATE_ITEMS',
          payload: { data },
        });
      }, 2000);
      dispatch({ type: 'SUCCESS' });
      return false;
    } else {
      dispatch({ type: 'SUCCESS' });
      return true;
    }
  }

  const removeItemHandler = async (item) => {
    // if (window.confirm('Artikel wirklich löschen?')) {
    let removedProduct = document.getElementById(item._id);
    removedProduct.style.opacity = '0';

    setTimeout(() => {
      ctxDispatch({
        type: 'CART_REMOVE_ITEM',
        payload: item,
      });
    }, 2000);

    ReactGA.event(
      `Artikel entfernen - ${item.category} - ${getSizeText(item)} - ${
        item.brand
      }`,
      { ...item }
    );

    await axios.get('/api/users/removeproduct', {
      headers: {
        userid: id,
        cart: cartItems.map((c) => c._id),
        page: `Artikel`,
        productid: item._id,
      },
    });
  };

  function RemoveProductModal(props) {
    return (
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="remove-product-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Artikel wirklich löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4} sm={4} md={3}>
              <img
                src={selectedProduct.image?.replace('.png', '_lowres.png')}
                alt={selectedProduct.name}
                className="img-fluid rounded img-thumbnail"
              />
            </Col>
            <Col xs={5} sm={5} md={7} lg={8}>
              <Stack gap={0}>
                <div
                  className="fw-bold"
                  style={{
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                >
                  {selectedProduct.category}
                </div>
                <div>{selectedProduct.brand}</div>
                <div style={{ minHeight: '1.5rem' }}>
                  {/* {selectedProduct.description}*/}
                </div>
                <div className="text_cart__size">
                  {selectedProduct.sizeHat &&
                  selectedProduct.sizeHat.length > 0 ? (
                    <>
                      {selectedProduct.sizeHat.length >= 2 ? (
                        <div>
                          Hutgr.{` `}
                          {Math.min(...selectedProduct.sizeHat)} -{' '}
                          {Math.max(...selectedProduct.sizeHat)}
                        </div>
                      ) : (
                        <div>
                          Hutgr.{` `}
                          {selectedProduct.sizeHat[0]}
                        </div>
                      )}
                    </>
                  ) : (
                    <>Gr. {selectedProduct.size}</>
                  )}
                </div>
              </Stack>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Row className="w-100">
            <Col
              xs={{ span: 5, offset: 1 }}
              sm={{ span: 4, offset: 2 }}
              md={{ span: 4, offset: 2 }}
              className="d-flex justify-content-center"
            >
              <div
                className="btn_action w-100 no-mw"
                onClick={() => {
                  props.removeItemHandler(selectedProduct);
                  props.onHide();
                }}
              >
                Ja
              </div>
            </Col>
            <Col
              xs={5}
              sm={{ span: 4, offset: 0 }}
              md={{ span: 4, offset: 0 }}
              className="d-flex justify-content-center"
            >
              <div className="btn_action w-100 no-mw" onClick={props.onHide}>
                Nein
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    const checkCart = async () => {
      await checkCartHandler();
    };
    if (cartItems.length > 0) checkCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  return (
    <aside className={sideMenuClasses}>
      <div
        className={'side-menu__overlay side-menu-right__overlay'}
        onClick={props.onOverLayClick}
      />
      <div className={sideMenuContentClasses}>
        <RemoveProductModal
          show={showRemoveProductModal}
          onHide={() => setShowRemoveProductModal(false)}
          removeItemHandler={removeItemHandler}
        />
        <Row className="mb-3">
          <Col>
            <div
              className="closeSideMenuRight d-flex justify-content-start"
              onClick={props.onOverLayClick}
            >
              <FontAwesomeIcon
                icon="rectangle-xmark"
                style={{ color: '#333' }}
                size="xl"
              />
            </div>
          </Col>
          <Col className="text-end">
            <h4>Warenkorb</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="g-0">
            <ListGroup variant="flush" className="cartList">
              {cartItems.length === 0 ? (
                <ListGroup.Item key="empty_card" className="my-2">
                  Huch, dein Warenkorb ist noch leer. Schnell zugreifen, bevor
                  jemand schneller ist!
                </ListGroup.Item>
              ) : (
                <React.Fragment>
                  <ListGroup.Item key="cart_header" className="cart_header">
                    <Row className="d-flex align-items-end h-100">
                      <Col className="text-start">Deine Artikel</Col>
                      <Col className="text-end">Preis</Col>
                    </Row>
                  </ListGroup.Item>
                  {cartItems.map((item) => (
                    <ListGroup.Item
                      key={item._id}
                      className="cartList__item mt-2"
                      id={item._id}
                    >
                      <Row>
                        <Col xs={4}>
                          <img
                            src={item.image?.replace('.png', '_lowres.png')}
                            alt={item.name}
                            className="img-fluid rounded img-thumbnail"
                            onClick={() => {
                              onOverLayClick();
                              navigate(`/product/${item._id}`);
                            }}
                          />
                        </Col>
                        <Col
                          xs={5}
                          onClick={() => {
                            onOverLayClick();
                            navigate(`/product/${item._id}`);
                          }}
                        >
                          <Stack gap={0}>
                            <div
                              className="fw-bold"
                              style={{
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}
                            >
                              {item.category}
                            </div>
                            <div>{item.brand}</div>
                            <div style={{ minHeight: '1.5rem' }}>
                              {/* {item.description} */}
                            </div>
                            <div className="text_cart__size">
                              {item.sizeHat && item.sizeHat.length > 0 ? (
                                <>
                                  {item.sizeHat.length >= 2 ? (
                                    <div>
                                      Hutgr.{` `}
                                      {Math.min(...item.sizeHat)} -{' '}
                                      {Math.max(...item.sizeHat)}
                                    </div>
                                  ) : (
                                    <div>
                                      Hutgr.{` `}
                                      {item.sizeHat[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>Gr. {item.size}</>
                              )}
                            </div>
                          </Stack>
                        </Col>
                        <Col xs={3}>
                          {!pathname.startsWith('/bestellung') && (
                            <Row className="h-50 justify-content-end">
                              <Col className="text-end">
                                <FontAwesomeIcon
                                  icon="xmark"
                                  onClick={() => {
                                    setSelectedProduct(item);
                                    setShowRemoveProductModal(true);
                                  }} //removeItemHandler(item)}
                                  size="lg"
                                />
                              </Col>
                            </Row>
                          )}
                          {item.sale ? (
                            <>
                              <Row
                                className={`${
                                  pathname.startsWith('/bestellung')
                                    ? 'h-75'
                                    : 'h-25'
                                } justify-content-end`}
                              >
                                <Col
                                  className="align-self-end h6 text-end"
                                  style={{
                                    textDecoration: 'line-through',
                                    color: '#777777',
                                  }}
                                >
                                  {item &&
                                    item.price.toLocaleString('de-DE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    })}
                                </Col>
                              </Row>
                              <Row className="h-25 justify-content-end">
                                <Col
                                  className="align-self-end h6 text-end"
                                  style={{ color: 'red' }}
                                >
                                  {(
                                    Math.round(
                                      item.price *
                                        (1 - item.discount / 100) *
                                        1e2
                                    ) / 1e2
                                  ).toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row
                              className={`${
                                pathname.startsWith('/bestellung')
                                  ? item.sale
                                    ? 'h-25'
                                    : 'h-50'
                                  : item.sale
                                  ? 'h-25'
                                  : 'h-50'
                              } justify-content-end`}
                            >
                              <Col className="align-self-end h6 text-end">
                                {item &&
                                  item.price.toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                  <ListGroup.Item key="cart_total" className="cart_footer">
                    {cartItems.some((item) => item.sale) && (
                      <Row className="mt-3 mb-0 text-end h6">
                        <Col
                          style={{
                            textDecoration: 'line-through',
                            color: '#777777',
                          }}
                        >
                          {cartItems
                            .reduce((a, c) => a + c.price, 0)
                            .toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                        </Col>
                      </Row>
                    )}
                    <Row
                      className={`${
                        cartItems.some((item) => item.sale) ? 'mt-0' : 'mt-3'
                      } text-end h6`}
                    >
                      <Col xs={9}>
                        <Stack gab={0}>
                          <div>Summe ({cartItems.length} Artikel):</div>
                          <div className="cart_total__footer text-end"></div>
                        </Stack>
                      </Col>
                      <Col
                        style={{
                          color: cartItems.some((item) => item.sale)
                            ? 'red'
                            : 'black',
                        }}
                      >
                        {cartItems
                          .reduce(
                            (a, c) =>
                              c.sale
                                ? a +
                                  Math.round(
                                    c.price * (1 - c.discount / 100) * 1e2
                                  ) /
                                    1e2
                                : a + c.price,
                            0
                          )
                          .toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </React.Fragment>
              )}
            </ListGroup>
          </Col>
          {loading && (
            <Col xs={12}>
              <LoadingBox />
            </Col>
          )}
          <Col>
            {cartItems.length > 0 &&
              !window.location.pathname.startsWith('/bestellung') && (
                <Row
                  className="mx-3 mt-5 mb-5 justify-content-center"
                  style={{ paddingBottom: '100px' }}
                >
                  <div
                    onClick={() => checkoutHandler()}
                    disabled={cartItems.length === 0}
                    className="btn_action"
                  >
                    {`Zur Kasse >`}
                  </div>
                </Row>
              )}
          </Col>
        </Row>
      </div>
    </aside>
  );
}

export default CartSidebar;

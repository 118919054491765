import React from 'react';
import { Col, ListGroup, Row, Stack } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { SocialIcon } from 'react-social-icons';
import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <footer ref={ref}>
      {inView ? (
        <Row className="g-0">
          <Col className="g-0 w-100">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row
                  className="d-flex justify-content-center text-center"
                  style={{ zIndex: 0 }}
                >
                  <div
                    className="text-center fw-bold font-underline mb-3"
                    style={{ fontSize: '0.9em' }}
                  >
                    Folgt & schreibt uns
                  </div>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="justify-content-center mb-3"
                  >
                    {' '}
                    {isBrowser ||
                    getMobileOperatingSystem() === 'Windows Phone' ||
                    getMobileOperatingSystem() === 'unknown' ? (
                      <SocialIcon
                        url="https://www.facebook.com/braunbaerchen.de/"
                        network="facebook"
                        style={{ height: '2em', width: '2em' }}
                      />
                    ) : (
                      <React.Fragment>
                        {getMobileOperatingSystem() === 'iOS' ? (
                          <SocialIcon
                            url="fb://page/?id=113727377140200"
                            network="facebook"
                            style={{ height: '2em', width: '2em' }}
                          />
                        ) : (
                          <SocialIcon
                            url="fb://page/113727377140200"
                            network="facebook"
                            style={{ height: '2em', width: '2em' }}
                          />
                        )}
                      </React.Fragment>
                    )}
                    <SocialIcon
                      url="https://www.instagram.com/braunbaerchen.de/"
                      style={{ height: '2em', width: '2em' }}
                    />
                    <SocialIcon
                      network="whatsapp"
                      url="https://wa.me/4922678710461"
                      style={{ height: '2em', width: '2em' }}
                    />
                    <SocialIcon
                      url="mailto:info@braunbaerchen.de"
                      style={{ height: '2em', width: '2em' }}
                    />
                  </Stack>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="mb-3">
                  <div
                    className="text-center fw-bold font-underline mb-3"
                    style={{ fontSize: '0.9em' }}
                  >
                    Unsere Preise
                  </div>
                  <div className="text-center">
                    Alle angegebenen Preise sind Endpreise. Aufgrund des
                    Kleinunternehmerstatus gem. § 19 UStG erheben wir keine
                    Umsatzsteuer und weisen diese daher auch nicht aus.
                  </div>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item className="px-2">
                <div
                  className="text-center fw-bold font-underline mb-3"
                  style={{ fontSize: '0.9em' }}
                >
                  Zum Schluss noch etwas rechtliches
                </div>
                <Row className="d-flex justify-content-center text-center text-nowrap footerlist mx-3 mb-3">
                  <div>
                    <Link to="/agb">AGB</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ fontSize: '0.25em' }}
                    />
                  </div>
                  <div>
                    <Link to="/dsgvo">Datenschutz</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ fontSize: '0.25em' }}
                    />
                  </div>
                  <div>
                    <Link to="/impressum">Impressum</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ fontSize: '0.25em' }}
                    />
                  </div>
                  <div>
                    <Link to="/widerruf">Widerrufsbelehrung</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ fontSize: '0.25em' }}
                    />
                  </div>
                  <div>
                    <Link to="/zahlung&versand">Zahlung & Versand</Link>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ fontSize: '0.25em' }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      window.CCM.openWidget();
                      return false;
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Cookie Einstellungen
                  </div>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="text-center p-3">
                  <img
                    src="/logoBB.svg"
                    height="120"
                    className="d-inline-block align-top"
                    alt="BraunBärchen logo"
                  />
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      ) : null}
    </footer>
  );
};

export default Footer;
